import Vue from 'vue'
import App from './App.vue'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import router from '@/router'
import store from "@/store"
import axios from "@/api/axios"

import Msg from "@/utils/Msg"
import global from "@/global/global.js"

import "./global/global.css"


Vue.use(ElementUI)


Vue.config.productionTip = false

// 全局
//使用时：this.$global.xxx
Vue.prototype.$axios = axios;
Vue.prototype.$Msg = Msg;
Vue.prototype.$global = global;


const vm = new Vue({
	store,
	router,
  render: h => h(App),
}).$mount('#app')
