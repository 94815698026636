import Vue from "vue";
import "babel-polyfill"
import Vuex from "vuex";

Vue.use(Vuex);

let state = {
  userInfo: {},
  openKeys: [],
  messageData: '',
  sysMessage: '',
  httpError: {
    hasError: false,
    status: "",
    statusText: "",
  }
};

const mutations = {
  ON_HTTP_ERROR(state, payload) {
    state.httpError = payload;
  },
  setMapState(state,payload){
    state.userInfo = payload
  },

  setMapOpenkeys(state,payload){
    state.openKeys = payload
  },
  getMessage(state,payload){
    state.messageData = payload;
  },
  sysMessage(state,payload){
    state.sysMessage = payload;
  },
};

export default new Vuex.Store({
  state,
  mutations
});
