<template>
  <div id="app">
		<router-view></router-view>
  </div>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
    HelloWorld
  }
}
</script>

<style>
html,body{
	margin: 0;
	background-color: #EEF1F6;
	
}
#app {
  height: 98vh;
}
</style>
