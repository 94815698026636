// 接口地址
const baseUrl = "https://api.sjzeis.com";
// 左侧菜单
let menuList = []	





export default {
	baseUrl,
	menuList
};