// 全局 提示消息
import { Message } from 'element-ui';

function successMsg(str){
	if(str == null || str ==""|| str == undefined){
		str = "Success";
	}
	Message.success({
		message: str,
		duration: 2000 //显示时间, 毫秒
	})
}

function warningMsg(str){
	if(str == null || str ==""|| str == undefined){
		str = "warning";
	}
	Message.warning({
		message: str,
		duration: 2000 //显示时间, 毫秒
	})
}

function infoMsg(str){
	if(str == null || str ==""|| str == undefined){
		str = "only info";
	}
	Message.info({
		message: str,
		duration: 2000 //显示时间, 毫秒
	})
}

function errorMsg(str){
	if(str == null || str ==""|| str == undefined){
		str = "System Error. Pls contact 墨竹闲人";
	}
	Message.error({
		message: str,
		duration: 2000 //显示时间, 毫秒
	})
}

// export default instance;
export default {
  successMsg,
	warningMsg,
	infoMsg,
	errorMsg
};