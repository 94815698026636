import Vue from "vue"
import VueRouter from "vue-router";
import routes from "./router";
import { getCookie } from "@/api/cookie";

Vue.use(VueRouter);


const router = new VueRouter({
  mode: "history",
  routes
});


router.beforeEach((to, form, next) => {
  // 获取用户token 判断用户是否登录
  const token = getCookie("TOKEN");

	if (token != undefined && token != "undefined" && token ) {
    if (to.path === "/login") {
      next("/");
    } else {
      if (to.matched.length === 0) {
        next("/404"); // 判断此跳转路由的来源路由是否存在，存在的情况跳转到来源路由，否则跳转到404页面
      }
      next();
    }
  } else {
    if (to.path === "/login") {
      next();
    } else {
      next(`/login`);
    }
  }
});

export default router; 