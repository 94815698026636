import msg from "@/utils/Msg"
import "babel-polyfill"
import axios from "axios";
import store from "@/store";
import router from "@/router";
import { getCookie, removeCookie } from "./cookie";

// const urlPre = "http://127.0.0.1:9000/single/";
const urlPre = "http://47.113.221.68/single/";

const toLogin = () => {
  router.push({
    path: "/login"
  });
};

const errorHandle = (status, other) => {
  // 状态码判断
  switch (status) {
    case 401:
      toLogin();
      break;
    case 403:
		case 406:
      msg.warningMsg("登录过期，请重新登录"); 
      removeCookie("TOKEN");
      setTimeout(() => {
        toLogin();
      }, 1000);
      break;
    case 404:
      msg.warningMsg("请求的资源不存在");
      break;
    case 500:
      toError();
      break;
    default:
      console.log(other);
  }
};

var instance = axios.create({
  baseURL: urlPre
});

// 请求拦截器
instance.interceptors.request.use(
	
  config => {
    // 每次发送请求之前判断vuex中是否存在token
    const token = getCookie("TOKEN");
    token && (config.headers["TOKEN"] = token);
    return config;
		
  },
	
  error => Promise.error(error)
);

// 响应拦截器
instance.interceptors.response.use(
  // 请求成功
  res => (res.status === 200 ? Promise.resolve(res) : Promise.reject(res)),
  // 请求失败
  error => {
    const { response } = error;
    if (response) {
      // 请求已发出，但是不在2xx的范围
      errorHandle(response.status, response.data.message);
      return Promise.reject(response);
    } else {
      if (!window.navigator.onLine) {
        store.commit("changeNetwork", false);
      } else {
        return Promise.reject(error);
      }
    }
  }
);
/**
 * get方法
 * @param url
 * @param data
 * @returns {Promise}
 */
const get = url => {
  return new Promise((resolve, reject) => {
    instance.get(urlPre + url).then(response => {
        resolve(response);
      })
      .catch(err => {
        reject(err);
      });
  });
};

/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */
const post = (url, data) => {
	data = JSON.stringify(data); 
  return new Promise((resolve, reject) => {
    instance.post(urlPre + url, data,{ headers: {'Content-Type':'application/json'} }).then(
      response => {
        resolve(response);
      },
      err => {
        reject(err);
      }
    );
  });
};

/**
 * 封装上传文件方法
 * @param {*} url 
 * @param {*} data 
 * @param {*} header 
 * @returns 
 */
const postFile = (url, data) => {
  return new Promise((resolve, reject) => {
    instance.post(urlPre + url, data, { headers: {'Content-Type':'multipart/form-data'}} ).then(
      response => {
        resolve(response);
      },
      err => {
        reject(err);
      }
    );
  });
};

// export default instance;
export default {
  get,
  post,
	postFile
};
