export default [
	{
		path: "/",
		name: "Main",
		component: () => import("@/views/Main.vue"),
		children: [
			{
				name: "Home",
				path: "/home",
				meta: { title: "首页" },
				component: () => import("@/views/Home.vue"),
			},
			/************************************    系统设置 start   *********************************/ 
			{
				name: "User",
				path: "/admin/system/user",
				meta: { title: "用户管理" },
				component: () => import("@/views/system/user/User.vue"),
			},
			{
				name: "Role",
				path: "/admin/system/role",
				meta: { title: "角色管理" },
				component: () => import("@/views/system/role/Role.vue"),
			},
			{
				name: "Menu",
				path: "/admin/system/menu",
				meta: { title: "菜单管理" },
				component: () => import("@/views/system/menu/Menu.vue"),
			},
			{
				name: "Resource",
				path: "/admin/system/resource",
				meta: { title: "资源管理" },
				component: () => import("@/views/system/resource/Resource.vue"),
			},
			/************************************    系统设置 end   *********************************/ 
			/************************************    tally 管理 start   *********************************/ 
			{
				name: "PayType",
				path: "/admin/tally/paytype",
				meta: { title: "支付类型" },
				component: () => import("@/views/tally/PayType.vue"),
			},
			{
				name: "CostType",
				path: "/admin/tally/costtype",
				meta: { title: "支付类型" },
				component: () => import("@/views/tally/CostType.vue"),
			},
			/************************************    tally 管理 end   *********************************/ 

			/************************************    日志  start   *********************************/ 
			{
				name: "LoginLog",
				path: "/admin/log/login",
				meta: { title: "登录日志" },
				component: () => import("@/views/log/LoginLog.vue"),
			},
			{
				name: "OperateLog",
				path: "/admin/log/operate",
				meta: { title: "操作日志" },
				component: () => import("@/views/log/OperateLog.vue"),
			},
			{
				name: "UserLoginLog",
				path: "/admin/log/userlogin",
				meta: { title: "用户登录日志" },
				component: () => import("@/views/log/UserLoginLog.vue"),
			},
			{
				name: "UserOperateLog",
				path: "/admin/log/useroperate",
				meta: { title: "用户操作日志" },
				component: () => import("@/views/log/UserOperateLog.vue"),
			},

			/************************************    日志 end   *********************************/ 

			/************************************    用户记账 start   *********************************/ 
			{
				name: "UserTally",
				path: "/user/tally/listpage",
				meta: { title: "用户记账" },
				component: () => import("@/views/user/tally/UserTally.vue"),
			},
			
			{
				name: "UserPassword",
				path: "/user/password/listpage",
				meta: { title: "用户密码管理" },
				component: () => import("@/views/user/pwd/UserPassword.vue"),
			},
			{
				name: "UserEvent",
				path: "/user/event/listpage",
				meta: { title: "用户事件管理" },
				component: () => import("@/views/user/event/UserEvent.vue"),
			},
			{
				name: "UserCostType",
				path: "/user/costtype/pagelist",
				meta: { title: "用户消费类型管理" },
				component: () => import("@/views/user/costtype/UserCostType.vue"),
			},
			/************************************    用户记账 end   *********************************/ 

			/************************************    生活管理 start   *********************************/ 
			{
				name: "Life",
				path: "/life/ns/listPage",
				meta: { title: "NS记录" },
				component: () => import("@/views/life/ns/lifeNs.vue"),
			},
			/************************************    生活管理 end   *********************************/ 

			/************************************    用户统计 start   *********************************/ 
			{
				name: "UserCostTypeEcharts",
				path: "/user/echarts/costType",
				meta: { title: "消费类型统计" },
				component: () => import("@/views/userecharts/UserCostTypeEcharts.vue"),
			},
			{
				name: "UserCostTypeEchartsOne",
				path: "/user/echarts/costTypeOne",
				meta: { title: "消费类型一级统计" },
				component: () => import("@/views/userecharts/UserCostTypeEchartsOne.vue"),
			},
			{
				name: "UserCostTypeEchartsAllOne",
				path: "/user/echarts/costTypeLevelOne",
				meta: { title: "所有一级统计" },
				component: () => import("@/views/userecharts/UserCostTypeEchartsAllOne.vue"),
			},
			{
				name: "UserCostTypeEchartsPie",
				path: "/user/echarts/costTypePie",
				meta: { title: "用户消费类型饼形图" },
				component: () => import("@/views/userecharts/UserCostTypeEchartsPie.vue"),
			},
			{
				name: "UserCostTypeEchartsTongbi",
				path: "/user/echarts/tongbi",
				meta: { title: "用户消费类型饼形图" },
				component: () => import("@/views/userecharts/UserCostTypeEchartsTongbi.vue"),
			},
			{
				name: "nsEcharts",
				path: "/lifeEcharts/ns",
				meta: { title: "NS统计" },
				component: () => import("@/views/userecharts/nsEcharts.vue"),
			},
			/************************************    用户 借贷  *********************************/ 
			{
				name: "UserBorrow",
				path: "/user/borrow/toborrowlist",
				meta: { title: "借贷记录" },
				component: () => import("@/views/user/borrow/UserBorrow.vue"),
			},
			/************************************    用户 借贷  *********************************/ 

			/************************************    用户 规划  *********************************/ 
			{
				name: "UserPlan",
				path: "/user/plan/pageList",
				meta: { title: "借贷记录" },
				component: () => import("@/views/user/plan/UserPlan.vue"),
			},
			/************************************    用户 规划  *********************************/ 

			/************************************    用户统计 end   *********************************/ 
			{
				name: "ResetPassword",
				path: "/system/ResetPassword",
				meta: { title: "重置密码" },
				component: () => import("@/views/system/ResetPassword.vue"),
			},

			/************************************    小程序 start   *********************************/ 
			
			{
				name: "Swiper",
				path: "/wx/swiper/listpage",
				meta: { title: "轮播图管理" },
				component: () => import("@/views/system/wx/Swiper.vue"),
			},
			
			/************************************    小程序 end   *********************************/ 


			
		]
	},
	{
    path: "/login",
    name: "Login",
    meta: { title: "登录" },
    component: () => import("@/views/Login.vue")
  }


]