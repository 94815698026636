import Cookie from 'js-cookie';

// 获取Cookie
export function getCookie() {
  return Cookie.get("TOKEN") ? Cookie.get("TOKEN") : '';
}

// 设置Cookie
export function setCookie( val, expires = 1) {
  console.log("==========================  set cookie ===============")
  Cookie.set("TOKEN", val, { expires: 1 });
  return true;
}

// 清除cookie
export function removeCookie() {
  Cookie.remove("TOKEN");
	Cookie.remove("menuList");
	Cookie.remove("userInfo");
}

// 保存菜单list
export function setMenuList( val, expires = 1) {
  Cookie.set("menuList", JSON.stringify(val), { expires: 1 });
}
export function getMenuList() {
  return Cookie.get("menuList") ? JSON.parse(Cookie.get("menuList")) : '';
}
// 用户信息 /////////////////////////////////
export function setUserInfo( val, expires = 1) {
  Cookie.set("userInfo", JSON.stringify(val), { expires: 1 });
  return true;
}
export function getUserInfo() {
  return Cookie.get("userInfo") ? JSON.parse(Cookie.get("userInfo"))  : '';
}